import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { GetMenu, GetOccupationsList } from '../Util/Data';


import '../Css/Footer.css';
import IconFacebook from '../Svg/IconFacebook';
import IconLinkedin from '../Svg/IconLinkedin';
import IconWhatsapp from '../Svg/IconWhatsapp';
import IconPhone from '../Svg/IconPhone';
import IconEmail from '../Svg/IconEmail';
import IconPin from '../Svg/IconPin';
import IconClock from '../Svg/IconClock';
import IconArrowUp from '../Svg/IconArrowUp';

import logo from '../img/logo.png';

const Footer = (props) => {
    const navigate = useNavigate();

    const [stateMenu, setStateMenu] = useState([]);
    const [stateOccupationsList, setStateOccupationsList] = useState([]);
    
    useEffect(() => {
        setStateMenu(GetMenu());
        setStateOccupationsList(GetOccupationsList());
    }, []);


    return (
            <>
                <div className="footer">
                    <div>
                        <div className="first-line">
                            <a href="javascript:" onClick={() => { navigate("/"); window.scrollTo(0,0); }}><img className="footer-logo" src={ logo } alt="לוגו - אורית ליסק משרד עוכי דין"/></a>

                            <span className="social">
                                <a target="_blank" href="https://facebook.com/people/%D7%90%D7%95%D7%A8%D7%99%D7%AA-%D7%9C%D7%99%D7%A1%D7%A7/100003905236513" aria-label="קישור לחשבון פייסבוק">
                                    <IconFacebook style= {{width:'12px'}} />
                                </a>
                                <a target="_blank" href="https://www.linkedin.com/in/orit-lissak-b5b13767/" aria-label="קישור לחשבון לינקדאין">
                                    <IconLinkedin />
                                </a>
                                <a target="_blank" href="https://wa.me/972544268326" aria-label="קישור לוואטסאפ">
                                    <IconWhatsapp  style= {{width:'24px', height:'24px', margin:'10px' }}/>
                                </a>
                            </span>
                        </div>


                        <div className="links">
                            <div>
                                <h3>עו"ד אורית ליסק</h3>
                                <div className="divider-space h3"></div>
                                <div className="divider-line h3"></div>
                                <div className="divider-space h2"></div>
                                <ul>
                                    { stateMenu.map((menuItem) => { 
                                        return (
                                            <li key={menuItem.id}><a href="javascript:" onClick={() => { navigate(menuItem.link); window.scrollTo(0,0); }}>{menuItem.title}</a></li>
                                        )
                                    }) }

                                    <li><a href="/הצהרת נגישות">הצהרת נגישות</a></li>
                                </ul>
                            </div>

                            <div>
                                <h3>תחומי עיסוק</h3>
                                <div className="divider-space h3"></div>
                                <div className="divider-line h3"></div>
                                <div className="divider-space h2"></div>
                                <ul>
                                    { stateOccupationsList.filter(m => m.display).map((menuItem) => { 
                                        return (
                                            <li key={menuItem.id}><a href="javascript:" onClick={() => { navigate(menuItem.link); window.scrollTo(0,0); }}>{menuItem.title}</a></li>
                                        )
                                    }) }
                                </ul>
                            </div>

                            <div className="contact-links">
                                <h3>יצירת קשר</h3>
                                <div className="divider-space h3"></div>
                                <div className="divider-line h3"></div>
                                <div className="divider-space h2"></div>
                                <ul>
                                    <li>
                                        <IconPhone />
                                        <span><a href="tel:054-4268326">054-4268326</a></span>
                                    </li>
                                    <li>
                                        <IconEmail />
                                        <span><a href="mailto:orit@lissak-adv.com">orit@lissak-adv.com</a></span>
                                    </li>
                                    <li>
                                        <IconWhatsapp />
                                        <span><a href="https://wa.me/972544268326" target='_blank'>054-4268326</a></span>
                                    </li>
                                    <li>
                                        <IconPin />
                                        <span>חיים לנדאו 7א, רמלה</span>
                                    </li>
                                    <li>
                                        <IconClock />
                                        <span style={{whiteSpace:'nowrap'}}>ימים א'-ה' 09:00-18:00</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="copy">
                        &copy; כל הזכויות שמורות אורית ליסק, משרד עורכי דין.
                    </div>

                </div>


                <a href="javascript:" className="scroll-up" onClick={ () => { window.scrollTo(0, 0); } } aria-label="גלול לראש העמוד">
                    <IconArrowUp style={{fill: 'var(--color-icon)', height:'3rem', width:'3rem'}} />
                </a>
            </>
    );

};

export default Footer;